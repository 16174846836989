import React from "react"
import Layout from "../components/layout"
import DemoFooter from "../components/demoFooter"
import SEO from "../components/seo"
import ChainsImage from "../images/chains.png"
import BlurredMan from "../images/blurred_guy.png"
import Cruiser from "../images/cruiser.png"
import SectionLeft from "../components/sectionLeft"
import SectionRight from "../components/sectionRight"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock, faTable, faMoneyBill, faCogs, faMobile } from "@fortawesome/free-solid-svg-icons"

export default function() {
  return (
    <Layout>
      <SEO title={"Teardown Manager"} description={"TEARDOWN MANAGER helps dealers strip down power vehicles into individual parts and sell them on eBay"}/>

      <div className={"row justify-content-center my-5 d-none d-md-flex"}>
        <div className={"col col-md-6 text-center border-top border-bottom"}>
          <h5 style={{ lineHeight: "1.9em" }} className={"font-weight-bold"}>
            Teardown Manager can be integrated into your corporate website for a seamless transition to get your parts online and ready for sales.
          </h5>
        </div>
      </div>

      <div className={"row justify-content-around d-none d-md-flex my-5"}>
        <div className={'col text-center align-self-center'}>
          <h4>Monthly</h4>
          <h1>$250</h1>
          <p className={'small text-muted'}>
            This is a flat fee, we never take a % of sales, and don’t charge more for unlimited listings, plus you’ll get eBay VIP
          </p>
        </div>
        <div className={"col text-center align-self-end"}>
          <div className={"mb-3"}>
            <FontAwesomeIcon icon={faClock} size={"6x"}/>
          </div>
          <p className={'lead'}>Cut Listing Time</p>
          <a href={"/demo-request"} className={"btn btn-secondary w-100"}>Learn More</a>
        </div>
        <div className={"col text-center align-self-end"}>
          <div className={"mb-3"}>
            <FontAwesomeIcon icon={faTable} size={"6x"}/>
          </div>
          <p className={'lead'}>Compatibility Chart</p>
          <a href={"/demo-request"} className={"btn btn-secondary w-100"}>Learn More</a>
        </div>
        <div className={"col text-center align-self-end"}>
          <div className={"mb-3"}>
            <FontAwesomeIcon icon={faMoneyBill} size={"6x"}/>
          </div>
          <p className={'lead'}>Price Comparison</p>
          <a href={"/demo-request"} className={"btn btn-secondary w-100"}>Learn More</a>
        </div>
        <div className={"col text-center align-self-end"}>
          <div className={"mb-3"}>
            <FontAwesomeIcon icon={faCogs} size={"6x"}/>
          </div>
          <p className={'lead'}>Inventory Labels</p>
          <a href={"/demo-request"} className={"btn btn-secondary w-100"}>Learn More</a>
        </div>
        <div className={"col text-center align-self-end"}>
          <div className={"mb-3"}>
            <FontAwesomeIcon icon={faMobile} size={"6x"}/>
          </div>
          <p className={'lead'}>Mobile App</p>
          <a href={"/demo-request"} className={"btn btn-secondary w-100"}>Learn More</a>
        </div>
      </div>

      <SectionLeft image={ChainsImage} alt={"Bike chain"} title={"Advantage #1"}>
        <p>
          No more “crowd sourcing” like our competition, use real OEM data like microfiche and fitment provided by the OEM’s.
        </p>
      </SectionLeft>

      <SectionRight image={BlurredMan} alt={"Man on bike"} title={"Advantage #2"}>
        <p>Built-in tools to track profit for each unit</p>

        <p>Input mechanics hours</p>

        <p>Input cost of bike</p>

        <p>Input bin location for each part</p>

        <p>Track Inventory</p>
      </SectionRight>

      <SectionLeft image={Cruiser} alt={"Man on cruiser"} title={"Advantage #3"}>
        <p>Get access to eBay VIP</p>

        <p>Free Anchor Store ($350 value)</p>

        <p>Unlimited listings with zero insertion fees</p>

        <p>Unlimited listings with free subtitles <strong>(normally $1.50-$6.00 per listing)</strong></p>
      </SectionLeft>

      <DemoFooter/>
    </Layout>
  )
}
